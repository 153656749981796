import Keycloak, { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://sso.mycld.dev:8443',
  realm: 'meetcode',
  clientId: 'my-crm'
};

const keycloakInstance = new Keycloak(keycloakConfig);

export default keycloakInstance; 