import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

// Örnek veri
const initialCustomers = [
  {
    id: 1,
    name: 'Ahmet Yılmaz',
    company: 'ABC Şirketi',
    email: 'ahmet@abc.com',
    phone: '0532 123 4567',
  },
  {
    id: 2,
    name: 'Ayşe Demir',
    company: 'XYZ Ltd.',
    email: 'ayse@xyz.com',
    phone: '0533 765 4321',
  },
];

const CustomerList = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState(initialCustomers);
  const [globalFilter, setGlobalFilter] = useState('');

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          severity="success"
          onClick={() => handleEdit(rowData.id)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const handleEdit = (id: number) => {
    // Düzenleme işlemi burada yapılacak
    console.log('Edit customer:', id);
  };

  const confirmDelete = (customer: any) => {
    confirmDialog({
      message: t('customer.confirmDeleteMessage', { name: customer.name }),
      header: t('customer.confirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: t('customer.yes'),
      rejectLabel: t('customer.no'),
      accept: () => handleDelete(customer.id),
    });
  };

  const handleDelete = (id: number) => {
    setCustomers(customers.filter(customer => customer.id !== id));
  };

  const header = (
    <div className="flex justify-content-between">
      <h2 className="m-0">{t('customer.list')}</h2>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
      </span>
    </div>
  );

  return (
    <Layout>
      <div className="card">
        <DataTable
          value={customers}
          paginator
          rows={10}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}
          emptyMessage={t('customer.noCustomers')}
        >
          <Column field="name" header={t('customer.name')} sortable />
          <Column field="company" header={t('customer.company')} sortable />
          <Column field="email" header={t('customer.email')} sortable />
          <Column field="phone" header={t('customer.phone')} />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }} />
        </DataTable>
        <ConfirmDialog />
      </div>
    </Layout>
  );
};

export default CustomerList; 