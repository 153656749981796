import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceItem, InvoiceFormData } from '../../types/invoice';
import { Item } from '../../types/item';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Örnek müşteri listesi
const customers = [
  { id: '1', name: 'ABC Şirketi', email: 'info@abc.com', phone: '0532 123 4567', address: 'İstanbul' },
  { id: '2', name: 'XYZ Ltd.', email: 'info@xyz.com', phone: '0533 765 4321', address: 'Ankara' },
  { id: '3', name: 'DEF A.Ş.', email: 'info@def.com', phone: '0534 987 6543', address: 'İzmir' }
];

// Örnek kalem listesi (gerçek uygulamada API'den gelecek)
const predefinedItems: Item[] = [
  {
    id: '1',
    code: 'ITM001',
    name: 'Laptop',
    price: 1200,
    purchasePrice: 1000,
    vatRate: 18,
    unit: 'piece',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: '2',
    code: 'ITM002',
    name: 'Mouse',
    price: 50,
    purchasePrice: 30,
    vatRate: 18,
    unit: 'piece',
    createdAt: new Date(),
    updatedAt: new Date()
  }
];

const InvoiceForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showItemDialog, setShowItemDialog] = useState(false);
  const [editingItem, setEditingItem] = useState<InvoiceItem | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [selectedPredefinedItem, setSelectedPredefinedItem] = useState<Item | null>(null);

  const [formData, setFormData] = useState<InvoiceFormData>({
    billFrom: '',
    billTo: '',
    dueDate: new Date(),
    paymentDetails: '',
    notes: '',
    items: [],
    discount: 0,
    initialPayment: 0
  });

  const [currentItem, setCurrentItem] = useState<InvoiceItem>({
    id: '',
    description: '',
    rate: 0,
    quantity: 1,
    discount: 0,
    total: 0
  });

  const invoiceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedCustomer) {
      setFormData(prev => ({
        ...prev,
        billTo: `${selectedCustomer.name}\n${selectedCustomer.address}\n${selectedCustomer.phone}\n${selectedCustomer.email}`
      }));
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (selectedPredefinedItem) {
      setCurrentItem({
        id: '',
        description: selectedPredefinedItem.name,
        rate: selectedPredefinedItem.price,
        quantity: 1,
        discount: 0,
        total: selectedPredefinedItem.price
      });
    }
  }, [selectedPredefinedItem]);

  const calculateTotal = (item: Partial<InvoiceItem>): number => {
    const subtotal = (item.rate || 0) * (item.quantity || 1);
    const discountAmount = subtotal * ((item.discount || 0) / 100);
    return subtotal - discountAmount;
  };

  const handleItemChange = (field: keyof InvoiceItem, value: any) => {
    const updatedItem = { ...currentItem, [field]: value };
    updatedItem.total = calculateTotal(updatedItem);
    setCurrentItem(updatedItem);
  };

  const addOrUpdateItem = () => {
    const itemWithId = {
      ...currentItem,
      id: currentItem.id || uuidv4()
    };

    if (editingItem) {
      setFormData(prev => ({
        ...prev,
        items: prev.items.map(item => 
          item.id === editingItem.id ? itemWithId : item
        )
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        items: [...prev.items, itemWithId]
      }));
    }

    setShowItemDialog(false);
    setCurrentItem({
      id: '',
      description: '',
      rate: 0,
      quantity: 1,
      discount: 0,
      total: 0
    });
    setEditingItem(null);
  };

  const editItem = (item: InvoiceItem) => {
    setCurrentItem(item);
    setEditingItem(item);
    setShowItemDialog(true);
  };

  const deleteItem = (item: InvoiceItem) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter(i => i.id !== item.id)
    }));
  };

  const calculateInvoiceTotal = () => {
    const subtotal = formData.items.reduce((sum, item) => sum + item.total, 0);
    const discountAmount = subtotal * (formData.discount / 100);
    return subtotal - discountAmount - formData.initialPayment;
  };

  const actionBodyTemplate = (rowData: InvoiceItem) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          severity="success"
          onClick={() => editItem(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const itemDialog = (
    <Dialog
      visible={showItemDialog}
      onHide={() => {
        setShowItemDialog(false);
        setSelectedPredefinedItem(null);
      }}
      header={editingItem ? t('invoice.editItem') : t('invoice.addItem')}
      modal
      className="p-fluid"
      style={{ width: '450px' }}
    >
      <div className="field">
        <label htmlFor="predefinedItem">{t('invoice.selectItem')}</label>
        <Dropdown
          id="predefinedItem"
          value={selectedPredefinedItem}
          options={predefinedItems}
          onChange={(e) => setSelectedPredefinedItem(e.value)}
          optionLabel="name"
          filter
          filterBy="name,code"
          placeholder={t('invoice.selectItemPlaceholder')}
          valueTemplate={(item: Item | null) => {
            if (!item) return t('invoice.selectItemPlaceholder');
            return (
              <div>
                <strong>{item.code}</strong> - {item.name} (${item.price.toFixed(2)})
              </div>
            );
          }}
          itemTemplate={(item: Item) => (
            <div>
              <div><strong>{item.code}</strong> - {item.name}</div>
              <div className="text-sm text-500">
                {t('item.price')}: ${item.price.toFixed(2)} | 
                {t('item.vatRate')}: {item.vatRate}% | 
                {t('item.unit')}: {t(`item.unit.${item.unit}`)}
              </div>
            </div>
          )}
          className="mb-3"
        />
      </div>

      <div className="field">
        <label htmlFor="description">{t('invoice.description')}</label>
        <InputTextarea
          id="description"
          value={currentItem.description}
          onChange={(e) => handleItemChange('description', e.target.value)}
          rows={3}
        />
      </div>

      <div className="formgrid grid">
        <div className="field col">
          <label htmlFor="rate">{t('invoice.rate')}</label>
          <InputNumber
            id="rate"
            value={currentItem.rate}
            onValueChange={(e) => handleItemChange('rate', e.value)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>

        <div className="field col">
          <label htmlFor="quantity">{t('invoice.quantity')}</label>
          <InputNumber
            id="quantity"
            value={currentItem.quantity}
            onValueChange={(e) => handleItemChange('quantity', e.value)}
            min={1}
          />
        </div>
      </div>

      <div className="field">
        <label htmlFor="discount">{t('invoice.discount')} (%)</label>
        <InputNumber
          id="discount"
          value={currentItem.discount}
          onValueChange={(e) => handleItemChange('discount', e.value)}
          min={0}
          max={100}
        />
      </div>

      <div className="field">
        <label>{t('invoice.total')}</label>
        <div className="text-2xl font-bold">
          ${currentItem.total.toFixed(2)}
        </div>
      </div>

      <div className="flex justify-content-end">
        <Button
          label={t('common.save')}
          icon="pi pi-check"
          onClick={addOrUpdateItem}
        />
      </div>
    </Dialog>
  );

  const exportToPDF = async () => {
    if (!invoiceRef.current) return;

    try {
      const canvas = await html2canvas(invoiceRef.current, {
        scale: 2,
        useCORS: true,
        logging: false
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice-${formData.invoiceNumber || 'draft'}.pdf`);
    } catch (error) {
      console.error('PDF export failed:', error);
    }
  };

  return (
    <Layout>
      <div className="card">
        <Card 
          title={t('invoice.create')}
          header={
            <div className="flex justify-content-between align-items-center p-3">
              <h2 className="m-0">{t('invoice.create')}</h2>
              <Button
                icon="pi pi-file-pdf"
                label={t('invoice.exportPDF')}
                severity="secondary"
                onClick={exportToPDF}
              />
            </div>
          }
        >
          <div ref={invoiceRef}>
            <div className="grid">
              {/* Logo ve Başlık */}
              <div className="col-12 text-center mb-4">
                <h1 className="text-3xl font-bold mb-2">{t('app.title')}</h1>
                <div className="text-xl">{t('invoice.title')}</div>
                <div className="text-lg mt-2">
                  {t('invoice.number')}: {formData.invoiceNumber || '-'}
                </div>
              </div>

              {/* Fatura Bilgileri */}
              <div className="col-12 md:col-6">
                <div className="field">
                  <label htmlFor="billFrom">{t('invoice.billFrom')}</label>
                  <InputText
                    id="billFrom"
                    value={formData.billFrom}
                    onChange={(e) => setFormData(prev => ({ ...prev, billFrom: e.target.value }))}
                  />
                </div>
              </div>

              <div className="col-12 md:col-6">
                <div className="field">
                  <label htmlFor="billTo">{t('invoice.billTo')}</label>
                  <div className="p-fluid">
                    <Dropdown
                      id="customer"
                      value={selectedCustomer}
                      options={customers}
                      onChange={(e) => setSelectedCustomer(e.value)}
                      optionLabel="name"
                      placeholder={t('invoice.selectCustomer')}
                      className="mb-2"
                    />
                    <InputTextarea
                      id="billTo"
                      value={formData.billTo}
                      onChange={(e) => setFormData(prev => ({ ...prev, billTo: e.target.value }))}
                      rows={4}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6">
                <div className="field">
                  <label htmlFor="dueDate">{t('invoice.dueDate')}</label>
                  <Calendar
                    id="dueDate"
                    value={formData.dueDate}
                    onChange={(e) => setFormData(prev => ({ ...prev, dueDate: e.value || new Date() }))}
                    showIcon
                  />
                </div>
              </div>

              <div className="col-12 md:col-6">
                <div className="field">
                  <label htmlFor="paymentDetails">{t('invoice.paymentDetails')}</label>
                  <InputTextarea
                    id="paymentDetails"
                    value={formData.paymentDetails}
                    onChange={(e) => setFormData(prev => ({ ...prev, paymentDetails: e.target.value }))}
                    rows={3}
                  />
                </div>
              </div>

              {/* Kalemler Tablosu */}
              <div className="col-12">
                <div className="flex justify-content-between align-items-center mb-3">
                  <h3 className="m-0">{t('invoice.items')}</h3>
                  <Button
                    label={t('invoice.addItem')}
                    icon="pi pi-plus"
                    onClick={() => setShowItemDialog(true)}
                  />
                </div>

                <DataTable
                  value={formData.items}
                  showGridlines
                  stripedRows
                  size="small"
                >
                  <Column field="description" header={t('invoice.description')} />
                  <Column
                    field="rate"
                    header={t('invoice.rate')}
                    body={(rowData) => `$${rowData.rate.toFixed(2)}`}
                  />
                  <Column field="quantity" header={t('invoice.quantity')} />
                  <Column
                    field="discount"
                    header={t('invoice.discount')}
                    body={(rowData) => `${rowData.discount}%`}
                  />
                  <Column
                    field="total"
                    header={t('invoice.total')}
                    body={(rowData) => `$${rowData.total.toFixed(2)}`}
                  />
                  <Column body={actionBodyTemplate} />
                </DataTable>
              </div>

              {/* Toplam Bilgileri */}
              <div className="col-12">
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="notes">{t('invoice.notes')}</label>
                      <InputTextarea
                        id="notes"
                        value={formData.notes}
                        onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                        rows={3}
                        className="w-full"
                      />
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="discount">{t('invoice.discount')} (%)</label>
                      <InputNumber
                        id="discount"
                        value={formData.discount}
                        onValueChange={(e) => setFormData(prev => ({ ...prev, discount: e.value || 0 }))}
                        min={0}
                        max={100}
                        className="w-full"
                      />
                    </div>

                    <div className="field">
                      <label htmlFor="initialPayment">{t('invoice.initialPayment')}</label>
                      <InputNumber
                        id="initialPayment"
                        value={formData.initialPayment}
                        onValueChange={(e) => setFormData(prev => ({ ...prev, initialPayment: e.value || 0 }))}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        min={0}
                        className="w-full"
                      />
                    </div>

                    <div className="text-right">
                      <div className="mb-3">
                        <span className="font-bold">{t('invoice.subtotal')}:</span>
                        <span className="ml-3">${formData.items.reduce((sum, item) => sum + item.total, 0).toFixed(2)}</span>
                      </div>
                      <div className="mb-3">
                        <span className="font-bold">{t('invoice.totalDue')}:</span>
                        <span className="ml-3">${calculateInvoiceTotal().toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-content-end gap-2 mt-4">
            <Button
              label={t('common.cancel')}
              icon="pi pi-times"
              severity="secondary"
              onClick={() => navigate('/invoices')}
            />
            <Button
              label={t('common.save')}
              icon="pi pi-check"
              onClick={() => console.log('Save invoice:', formData)}
            />
          </div>
        </Card>
      </div>
      {itemDialog}
    </Layout>
  );
};

export default InvoiceForm; 