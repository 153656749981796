import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const ResetPassword = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    email: yup.string().email(t('validation.email')).required(t('validation.required', { field: t('common.email') })),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // API çağrısı burada yapılacak
        console.log(values);
        // Başarılı işlem sonrası kullanıcıya bilgi ver
      } catch (error) {
        console.error(t('common.error'), error);
      }
    },
  });

  return (
    <div className="flex align-items-center justify-content-center min-h-screen bg-blue-50">
      <div className="surface-card p-4 shadow-2 border-round w-full max-w-30rem">
        <Card title={t('common.resetPassword')}>
          <p className="text-center text-600 mb-4">
            {t('common.resetPasswordInstructions')}
          </p>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field mb-4">
              <span className="p-float-label">
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={formik.errors.email && formik.touched.email ? 'p-invalid' : ''}
                />
                <label htmlFor="email">{t('common.email')}</label>
              </span>
              {formik.touched.email && formik.errors.email && (
                <small className="p-error">{formik.errors.email}</small>
              )}
            </div>

            <Button
              type="submit"
              label={t('common.sendResetLink')}
              className="mt-2"
              icon="pi pi-envelope"
              loading={formik.isSubmitting}
            />

            <div className="text-center mt-4">
              <RouterLink to="/login" className="text-blue-500 hover:text-blue-700 no-underline">
                {t('common.backToLogin')}
              </RouterLink>
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword; 