import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';

// PrimeReact ve PrimeFlex stilleri
import 'primereact/resources/themes/soho-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// Global stiller
import './styles/global.css';

import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import CustomerList from './pages/CustomerList';
import NewCustomer from './pages/NewCustomer';
import UserList from './pages/UserManagement/UserList';
import UserForm from './pages/UserManagement/UserForm';
import RoleList from './pages/UserManagement/RoleList';
import RoleForm from './pages/UserManagement/RoleForm';
import InvoiceList from './pages/Invoice/InvoiceList';
import InvoiceForm from './pages/Invoice/InvoiceForm';
import ItemList from './pages/Items/ItemList';
import ItemForm from './pages/Items/ItemForm';
import ItemView from './pages/Items/ItemView';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Protected Routes */}
          <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/customers" element={<PrivateRoute><CustomerList /></PrivateRoute>} />
          <Route path="/customers/new" element={<PrivateRoute><NewCustomer /></PrivateRoute>} />
          
          {/* Invoice Routes */}
          <Route path="/invoices" element={<PrivateRoute><InvoiceList /></PrivateRoute>} />
          <Route path="/invoices/new" element={<PrivateRoute><InvoiceForm /></PrivateRoute>} />
          <Route path="/invoices/edit/:id" element={<PrivateRoute><InvoiceForm /></PrivateRoute>} />
          
          {/* Item Routes */}
          <Route path="/items" element={<PrivateRoute><ItemList /></PrivateRoute>} />
          <Route path="/items/new" element={<PrivateRoute><ItemForm /></PrivateRoute>} />
          <Route path="/items/edit/:id" element={<PrivateRoute><ItemForm /></PrivateRoute>} />
          <Route path="/items/view/:id" element={<PrivateRoute><ItemView /></PrivateRoute>} />
          
          {/* User Management Routes */}
          <Route path="/users" element={<PrivateRoute><UserList /></PrivateRoute>} />
          <Route path="/users/new" element={<PrivateRoute><UserForm /></PrivateRoute>} />
          <Route path="/users/edit/:id" element={<PrivateRoute><UserForm /></PrivateRoute>} />
          <Route path="/roles" element={<PrivateRoute><RoleList /></PrivateRoute>} />
          <Route path="/roles/new" element={<PrivateRoute><RoleForm /></PrivateRoute>} />
          <Route path="/roles/edit/:id" element={<PrivateRoute><RoleForm /></PrivateRoute>} />

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
