export type Permission = 'view' | 'create' | 'update' | 'delete';

export interface ResourcePermission {
  resource: string;
  permissions: Permission[];
}

export interface Role {
  id: string;
  name: string;
  description?: string;
  permissions: ResourcePermission[];
  isSystem?: boolean;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
}

export interface User {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Role[];
  isActive: boolean;
  lastLogin?: Date;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface LoginResponse {
  user: User;
  token: string;
}

export interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
}

// Örnek kaynak tanımları
export const Resources = {
  DASHBOARD: 'dashboard',
  USERS: 'users',
  ROLES: 'roles',
  CUSTOMERS: 'customers',
  COMPANIES: 'companies',
  FORMS: 'forms',
  SETTINGS: 'settings'
} as const;

// Örnek rol tanımları
export const SystemRoles = {
  ADMIN: 'admin',
  USER: 'user',
  GUEST: 'guest'
} as const;

// Yetki kontrol yardımcı fonksiyonları
export const hasPermission = (
  user: User | null,
  resource: string,
  permission: Permission
): boolean => {
  if (!user) return false;

  // Admin her şeye erişebilir
  if (user.roles.some(role => role.name === SystemRoles.ADMIN)) {
    return true;
  }

  return user.roles.some(role =>
    role.permissions.some(
      p => p.resource === resource && p.permissions.includes(permission)
    )
  );
};

export const hasAnyPermission = (
  user: User | null,
  resource: string,
  permissions: Permission[]
): boolean => {
  return permissions.some(permission => hasPermission(user, resource, permission));
};

export const hasAllPermissions = (
  user: User | null,
  resource: string,
  permissions: Permission[]
): boolean => {
  return permissions.every(permission => hasPermission(user, resource, permission));
}; 