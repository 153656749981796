import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Invoice } from '../../types/invoice';

// Örnek veri
const initialInvoices: Invoice[] = [
  {
    id: '1',
    invoiceNumber: 'INV-001',
    billFrom: 'ABC Company',
    billTo: 'XYZ Ltd.',
    dueDate: new Date('2024-02-15'),
    paymentDetails: 'Bank transfer',
    notes: 'Please pay within due date',
    items: [
      {
        id: '1',
        description: 'Web Development',
        rate: 100,
        quantity: 10,
        discount: 0,
        total: 1000
      }
    ],
    subtotal: 1000,
    discount: 0,
    initialPayment: 0,
    totalDue: 1000,
    status: 'sent',
    createdAt: new Date(),
    updatedAt: new Date()
  }
];

const InvoiceList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState<Invoice[]>(initialInvoices);
  const [globalFilter, setGlobalFilter] = useState('');

  const statusBodyTemplate = (rowData: Invoice) => {
    const severity = {
      draft: 'info',
      sent: 'warning',
      paid: 'success',
      overdue: 'danger'
    }[rowData.status];

    return <Tag value={t(`invoice.status.${rowData.status}`)} severity={severity as any} />;
  };

  const amountBodyTemplate = (rowData: Invoice) => {
    return `$${rowData.totalDue.toFixed(2)}`;
  };

  const dateBodyTemplate = (rowData: Invoice) => {
    return new Date(rowData.dueDate).toLocaleDateString();
  };

  const actionBodyTemplate = (rowData: Invoice) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          severity="success"
          onClick={() => navigate(`/invoices/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const confirmDelete = (invoice: Invoice) => {
    confirmDialog({
      message: t('invoice.confirmDeleteMessage', { number: invoice.invoiceNumber }),
      header: t('invoice.confirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: t('common.yes'),
      rejectLabel: t('common.no'),
      accept: () => handleDelete(invoice.id)
    });
  };

  const handleDelete = (id: string) => {
    setInvoices(invoices.filter(invoice => invoice.id !== id));
  };

  const header = (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0">{t('invoice.list')}</h2>
      <div className="flex gap-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t('common.search')}
          />
        </span>
        <Button
          label={t('invoice.create')}
          icon="pi pi-plus"
          severity="success"
          onClick={() => navigate('/invoices/new')}
        />
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="card">
        <DataTable
          value={invoices}
          paginator
          rows={10}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}
          emptyMessage={t('invoice.noInvoices')}
          stripedRows
          showGridlines
          size="small"
        >
          <Column field="invoiceNumber" header={t('invoice.number')} sortable />
          <Column field="billTo" header={t('invoice.billTo')} sortable />
          <Column
            field="dueDate"
            header={t('invoice.dueDate')}
            body={dateBodyTemplate}
            sortable
          />
          <Column
            field="totalDue"
            header={t('invoice.amount')}
            body={amountBodyTemplate}
            sortable
          />
          <Column
            field="status"
            header={t('invoice.status')}
            body={statusBodyTemplate}
            sortable
          />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
        </DataTable>
        <ConfirmDialog />
      </div>
    </Layout>
  );
};

export default InvoiceList; 