import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import keycloak from '../../config/keycloak';

interface KeycloakUser {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  emailVerified: boolean;
  attributes?: {
    [key: string]: string[];
  };
}

const UserList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState<KeycloakUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      setLoading(true);
      
      // Token yenileme
      await keycloak.updateToken(30);
      
      // Keycloak Admin API'ye istek
      const response = await fetch(
        `${keycloak.authServerUrl}/admin/realms/${keycloak.realm}/users`,
        {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error loading users:', error);
    } finally {
      setLoading(false);
    }
  };

  const header = (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0">{t('user.title')}</h2>
      <div className="flex align-items-center gap-3">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t('common.search')}
          />
        </span>
        <Button
          label={t('user.add')}
          icon="pi pi-plus"
          onClick={() => navigate('/users/new')}
        />
      </div>
    </div>
  );

  const statusBodyTemplate = (user: KeycloakUser) => {
    return (
      <Tag
        value={user.enabled ? t('common.active') : t('common.inactive')}
        severity={user.enabled ? 'success' : 'danger'}
      />
    );
  };

  const emailVerifiedBodyTemplate = (user: KeycloakUser) => {
    return (
      <Tag
        value={user.emailVerified ? t('common.verified') : t('common.unverified')}
        severity={user.emailVerified ? 'success' : 'warning'}
      />
    );
  };

  const fullNameBodyTemplate = (user: KeycloakUser) => {
    return `${user.firstName || ''} ${user.lastName || ''}`.trim() || '-';
  };

  const actionBodyTemplate = (user: KeycloakUser) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="p-button-sm"
          onClick={() => navigate(`/users/edit/${user.id}`)}
        />
        <Button
          icon="pi pi-key"
          rounded
          outlined
          severity="warning"
          className="p-button-sm"
          onClick={() => navigate(`/users/${user.id}/reset-password`)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className="p-button-sm"
          onClick={() => handleDeleteUser(user)}
        />
      </div>
    );
  };

  const handleDeleteUser = async (user: KeycloakUser) => {
    if (!window.confirm(t('user.confirmDelete', { name: user.username }))) {
      return;
    }

    try {
      await keycloak.updateToken(30);
      
      const response = await fetch(
        `${keycloak.authServerUrl}/admin/realms/${keycloak.realm}/users/${user.id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${keycloak.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete user');
      }

      await loadUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <Layout>
      <Card className="mb-3">
        <DataTable
          value={users}
          header={header}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          globalFilter={globalFilter}
          emptyMessage={t('user.noUsers')}
          loading={loading}
          dataKey="id"
          stripedRows
          showGridlines
          size="small"
        >
          <Column field="username" header={t('user.username')} sortable />
          <Column body={fullNameBodyTemplate} header={t('user.name')} sortable />
          <Column field="email" header={t('user.email')} sortable />
          <Column body={statusBodyTemplate} header={t('common.status')} sortable />
          <Column
            body={emailVerifiedBodyTemplate}
            header={t('user.emailVerified')}
            sortable
          />
          <Column
            body={actionBodyTemplate}
            header={t('common.actions')}
            style={{ width: '12rem' }}
          />
        </DataTable>
      </Card>
    </Layout>
  );
};

export default UserList; 