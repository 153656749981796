import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Layout from '../components/Layout';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

interface FormValues {
  name: string;
  company: string;
  email: string;
  phone: string;
  notes: string;
}

const NewCustomer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
      notes: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('validation.required')),
      company: Yup.string().required(t('validation.required')),
      email: Yup.string().email(t('validation.email')).required(t('validation.required')),
      phone: Yup.string().required(t('validation.required')),
      notes: Yup.string()
    }),
    onSubmit: (values) => {
      console.log(values);
      navigate('/customers');
    }
  });

  const isFormFieldInvalid = (name: keyof FormValues) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: keyof FormValues) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : null;
  };

  return (
    <Layout>
      <Card title={t('customer.add')} className="mb-3">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="name">{t('customer.name')}</label>
                <InputText
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  className={isFormFieldInvalid('name') ? 'p-invalid' : ''}
                />
                {getFormErrorMessage('name')}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="company">{t('customer.company')}</label>
                <InputText
                  id="company"
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  className={isFormFieldInvalid('company') ? 'p-invalid' : ''}
                />
                {getFormErrorMessage('company')}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="email">{t('customer.email')}</label>
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={isFormFieldInvalid('email') ? 'p-invalid' : ''}
                />
                {getFormErrorMessage('email')}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="phone">{t('customer.phone')}</label>
                <InputText
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  className={isFormFieldInvalid('phone') ? 'p-invalid' : ''}
                />
                {getFormErrorMessage('phone')}
              </div>
            </div>

            <div className="col-12">
              <div className="field">
                <label htmlFor="notes">{t('customer.notes')}</label>
                <InputTextarea
                  id="notes"
                  name="notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  rows={3}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-content-end gap-2">
            <Button
              label={t('common.cancel')}
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => navigate('/customers')}
            />
            <Button
              label={t('common.save')}
              icon="pi pi-check"
              type="submit"
            />
          </div>
        </form>
      </Card>
    </Layout>
  );
};

export default NewCustomer; 