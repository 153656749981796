import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Item } from '../../types/item';

// Örnek veri
const initialItems: Item[] = [
  {
    id: '1',
    code: 'ITM001',
    name: 'Laptop',
    price: 1200,
    purchasePrice: 1000,
    vatRate: 18,
    unit: 'piece',
    createdAt: new Date(),
    updatedAt: new Date()
  }
];

const ItemView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState<Item | null>(null);

  useEffect(() => {
    if (id) {
      // Gerçek uygulamada API'den veri çekilecek
      const foundItem = initialItems.find(item => item.id === id);
      if (foundItem) {
        setItem(foundItem);
      }
    }
  }, [id]);

  if (!item) {
    return (
      <Layout>
        <div className="card">
          <Card>
            <div className="text-center">
              <h3>{t('item.notFound')}</h3>
              <Button
                label={t('common.back')}
                icon="pi pi-arrow-left"
                onClick={() => navigate('/items')}
              />
            </div>
          </Card>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="card">
        <Card
          title={t('item.details')}
          subTitle={item.code}
          header={
            <div className="flex justify-content-end p-3">
              <Button
                icon="pi pi-pencil"
                label={t('common.edit')}
                onClick={() => navigate(`/items/edit/${item.id}`)}
                className="mr-2"
              />
              <Button
                icon="pi pi-arrow-left"
                label={t('common.back')}
                severity="secondary"
                onClick={() => navigate('/items')}
              />
            </div>
          }
        >
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('item.code')}</label>
                <div className="text-900">{item.code}</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('item.name')}</label>
                <div className="text-900">{item.name}</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('item.price')}</label>
                <div className="text-900">${item.price.toFixed(2)}</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('item.purchasePrice')}</label>
                <div className="text-900">${item.purchasePrice.toFixed(2)}</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('item.vatRate')}</label>
                <div className="text-900">{item.vatRate}%</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('item.unit')}</label>
                <div className="text-900">{t(`item.unit.${item.unit}`)}</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('common.createdAt')}</label>
                <div className="text-900">{item.createdAt.toLocaleDateString()}</div>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label className="font-medium block mb-2">{t('common.updatedAt')}</label>
                <div className="text-900">{item.updatedAt.toLocaleDateString()}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default ItemView; 