import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Item, ItemUnit, ItemFormData } from '../../types/item';

// Örnek veri
const initialItems: Item[] = [
  {
    id: '1',
    code: 'ITM001',
    name: 'Laptop',
    price: 1200,
    purchasePrice: 1000,
    vatRate: 18,
    unit: 'piece',
    createdAt: new Date(),
    updatedAt: new Date()
  }
];

const ItemForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState<ItemFormData>({
    code: '',
    name: '',
    price: 0,
    purchasePrice: 0,
    vatRate: 18,
    unit: 'piece'
  });

  const units = [
    { label: t('item.unit.piece'), value: 'piece' },
    { label: t('item.unit.kg'), value: 'kg' },
    { label: t('item.unit.box'), value: 'box' }
  ];

  useEffect(() => {
    if (id) {
      // Gerçek uygulamada API'den veri çekilecek
      const item = initialItems.find(item => item.id === id);
      if (item) {
        setFormData({
          code: item.code,
          name: item.name,
          price: item.price,
          purchasePrice: item.purchasePrice,
          vatRate: item.vatRate,
          unit: item.unit
        });
      }
    }
  }, [id]);

  const handleSubmit = () => {
    setSubmitted(true);

    if (formData.code && formData.name && formData.price > 0) {
      // Gerçek uygulamada API'ye kaydedilecek
      console.log('Save item:', formData);
      navigate('/items');
    }
  };

  const isFormFieldValid = (field: keyof ItemFormData) => {
    if (submitted) {
      if (field === 'code' || field === 'name') {
        return !!formData[field];
      }
      if (field === 'price') {
        return formData[field] > 0;
      }
    }
    return true;
  };

  const getFormErrorMessage = (field: keyof ItemFormData) => {
    return isFormFieldValid(field) ? '' : t('validation.required');
  };

  return (
    <Layout>
      <div className="card">
        <Card title={id ? t('item.edit') : t('item.add')}>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="code" className="font-medium">
                  {t('item.code')}*
                </label>
                <InputText
                  id="code"
                  value={formData.code}
                  onChange={(e) => setFormData(prev => ({ ...prev, code: e.target.value }))}
                  className={classNames('w-full', { 'p-invalid': !isFormFieldValid('code') })}
                />
                <small className="p-error">{getFormErrorMessage('code')}</small>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="name" className="font-medium">
                  {t('item.name')}*
                </label>
                <InputText
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className={classNames('w-full', { 'p-invalid': !isFormFieldValid('name') })}
                />
                <small className="p-error">{getFormErrorMessage('name')}</small>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="price" className="font-medium">
                  {t('item.price')}*
                </label>
                <InputNumber
                  id="price"
                  value={formData.price}
                  onValueChange={(e) => setFormData(prev => ({ ...prev, price: e.value || 0 }))}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className={classNames('w-full', { 'p-invalid': !isFormFieldValid('price') })}
                />
                <small className="p-error">{getFormErrorMessage('price')}</small>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="purchasePrice" className="font-medium">
                  {t('item.purchasePrice')}
                </label>
                <InputNumber
                  id="purchasePrice"
                  value={formData.purchasePrice}
                  onValueChange={(e) => setFormData(prev => ({ ...prev, purchasePrice: e.value || 0 }))}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className="w-full"
                />
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="vatRate" className="font-medium">
                  {t('item.vatRate')}
                </label>
                <InputNumber
                  id="vatRate"
                  value={formData.vatRate}
                  onValueChange={(e) => setFormData(prev => ({ ...prev, vatRate: e.value || 0 }))}
                  suffix="%"
                  min={0}
                  max={100}
                  className="w-full"
                />
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="unit" className="font-medium">
                  {t('item.unit')}
                </label>
                <Dropdown
                  id="unit"
                  value={formData.unit}
                  options={units}
                  onChange={(e) => setFormData(prev => ({ ...prev, unit: e.value }))}
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-content-end gap-2 mt-4">
            <Button
              label={t('common.cancel')}
              icon="pi pi-times"
              severity="secondary"
              onClick={() => navigate('/items')}
            />
            <Button
              label={t('common.save')}
              icon="pi pi-check"
              onClick={handleSubmit}
            />
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default ItemForm; 