import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Role, Resources, Permission } from '../../types/auth';
import PermissionGuard from '../../components/PermissionGuard';

// Örnek veri
const initialRoles: Role[] = [
  {
    id: '1',
    name: 'Admin',
    description: 'Tam yetkili kullanıcı',
    permissions: [
      {
        resource: Resources.DASHBOARD,
        permissions: ['view']
      },
      {
        resource: Resources.USERS,
        permissions: ['view', 'create', 'update', 'delete']
      }
    ],
    isSystem: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: 'system'
  },
  {
    id: '2',
    name: 'User',
    description: 'Normal kullanıcı',
    permissions: [
      {
        resource: Resources.DASHBOARD,
        permissions: ['view']
      },
      {
        resource: Resources.CUSTOMERS,
        permissions: ['view', 'create']
      }
    ],
    isSystem: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: 'system'
  }
];

const RoleList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState<Role[]>(initialRoles);
  const [globalFilter, setGlobalFilter] = useState('');

  const handleEdit = (id: string) => {
    navigate(`/roles/edit/${id}`);
  };

  const confirmDelete = (role: Role) => {
    if (role.isSystem) {
      return;
    }

    confirmDialog({
      message: t('role.confirmDeleteMessage', { name: role.name }),
      header: t('role.confirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: t('common.yes'),
      rejectLabel: t('common.no'),
      accept: () => handleDelete(role.id),
    });
  };

  const handleDelete = (id: string) => {
    setRoles(roles.filter(role => role.id !== id));
  };

  const permissionsBodyTemplate = (rowData: Role) => {
    return rowData.permissions.map(p => (
      <div key={p.resource} className="mb-1">
        <strong>{t(`resource.${p.resource}`)}:</strong>{' '}
        {p.permissions.map(perm => t(`permission.${perm}`)).join(', ')}
      </div>
    ));
  };

  const actionBodyTemplate = (rowData: Role) => {
    return (
      <div className="flex gap-2">
        <PermissionGuard
          resource={Resources.ROLES}
          permissions={['update']}
        >
          <Button
            icon="pi pi-pencil"
            rounded
            text
            severity="success"
            onClick={() => handleEdit(rowData.id)}
            disabled={rowData.isSystem}
          />
        </PermissionGuard>
        
        <PermissionGuard
          resource={Resources.ROLES}
          permissions={['delete']}
        >
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            onClick={() => confirmDelete(rowData)}
            disabled={rowData.isSystem}
          />
        </PermissionGuard>
      </div>
    );
  };

  const header = (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0">{t('role.list')}</h2>
      <div className="flex gap-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t('common.search')}
          />
        </span>
        <PermissionGuard
          resource={Resources.ROLES}
          permissions={['create']}
        >
          <Button
            label={t('role.new')}
            icon="pi pi-plus"
            severity="success"
            onClick={() => navigate('/roles/new')}
          />
        </PermissionGuard>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="card">
        <DataTable
          value={roles}
          paginator
          rows={10}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}
          emptyMessage={t('role.noRoles')}
          stripedRows
          showGridlines
          size="small"
        >
          <Column field="name" header={t('role.name')} sortable />
          <Column field="description" header={t('role.description')} />
          <Column
            field="permissions"
            header={t('role.permissions')}
            body={permissionsBodyTemplate}
          />
          <Column
            field="createdAt"
            header={t('common.createdAt')}
            sortable
            body={(rowData) => new Date(rowData.createdAt).toLocaleDateString()}
          />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
        </DataTable>
        <ConfirmDialog />
      </div>
    </Layout>
  );
};

export default RoleList; 