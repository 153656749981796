import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { useAuth } from '../contexts/AuthContext';
import LanguageSwitcher from './LanguageSwitcher';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);

  const menuItems = [
    {
      label: t('menu.dashboard'),
      icon: 'pi pi-home',
      command: () => navigate('/')
    },
    {
      label: t('menu.customers'),
      icon: 'pi pi-users',
      items: [
        {
          label: t('menu.customerList'),
          icon: 'pi pi-list',
          command: () => navigate('/customers')
        },
        {
          label: t('menu.newCustomer'),
          icon: 'pi pi-plus',
          command: () => navigate('/customers/new')
        }
      ]
    },
    {
      label: t('menu.items'),
      icon: 'pi pi-box',
      items: [
        {
          label: t('menu.itemList'),
          icon: 'pi pi-list',
          command: () => navigate('/items')
        },
        {
          label: t('menu.newItem'),
          icon: 'pi pi-plus',
          command: () => navigate('/items/new')
        }
      ]
    },
    {
      label: t('menu.invoices'),
      icon: 'pi pi-file',
      items: [
        {
          label: t('menu.invoiceList'),
          icon: 'pi pi-list',
          command: () => navigate('/invoices')
        },
        {
          label: t('menu.newInvoice'),
          icon: 'pi pi-plus',
          command: () => navigate('/invoices/new')
        }
      ]
    },
    {
      label: t('menu.forms'),
      icon: 'pi pi-file-edit',
      items: [
        {
          label: t('menu.formList'),
          icon: 'pi pi-list',
          command: () => navigate('/forms')
        },
        {
          label: t('menu.newForm'),
          icon: 'pi pi-plus',
          command: () => navigate('/forms/new')
        }
      ]
    },
    {
      label: t('menu.users'),
      icon: 'pi pi-users',
      items: [
        {
          label: t('menu.userList'),
          icon: 'pi pi-list',
          command: () => navigate('/users')
        },
        {
          label: t('menu.roles'),
          icon: 'pi pi-key',
          command: () => navigate('/roles')
        }
      ]
    }
  ];

  const userMenuItems = [
    {
      label: t('auth.profile'),
      icon: 'pi pi-user',
      command: () => navigate('/profile')
    },
    {
      label: t('auth.settings'),
      icon: 'pi pi-cog',
      command: () => navigate('/settings')
    },
    {
      separator: true
    },
    {
      label: t('auth.logout'),
      icon: 'pi pi-power-off',
      command: () => logout()
    }
  ];

  return (
    <div className="min-h-screen flex flex-column relative">
      {/* Header */}
      <div className="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative" style={{ height: '60px' }}>
        <div className="flex align-items-center">
          <Link to="/" className="text-900 font-medium text-2xl">
            {t('app.title')}
          </Link>
        </div>
        <div className="flex align-items-center gap-3">
          <LanguageSwitcher />
          <Button
            icon="pi pi-user"
            rounded
            text
            severity="secondary"
            aria-label="User Menu"
            onClick={() => setShowUserMenu(true)}
          />
        </div>
      </div>

      {/* Sidebar */}
      <div className="flex flex-grow-1">
        <div className="surface-section border-right-1 surface-border w-18rem pt-3">
          <Menu model={menuItems} className="border-none w-full" />
        </div>

        {/* Main Content */}
        <div className="flex-grow-1 p-4">
          {children}
        </div>
      </div>

      {/* User Menu */}
      <Sidebar
        visible={showUserMenu}
        position="right"
        onHide={() => setShowUserMenu(false)}
        className="w-18rem"
      >
        <div className="flex flex-column align-items-center mb-4">
          <Avatar
            icon="pi pi-user"
            size="large"
            shape="circle"
            className="mb-2"
          />
          <span className="font-medium">John Doe</span>
          <span className="text-sm text-600">Administrator</span>
        </div>
        <Menu model={userMenuItems} className="border-none w-full" />
      </Sidebar>
    </div>
  );
};

export default Layout; 