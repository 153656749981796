import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validation.email')).required(t('validation.required', { field: 'Email' })),
      password: Yup.string().required(t('validation.required', { field: t('auth.password') })),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t('validation.passwordMatch'))
        .required(t('validation.required', { field: t('auth.password') }))
    }),
    onSubmit: async (values) => {
      // API'ye kayıt isteği gönderilecek
      console.log('Signup values:', values);
      navigate('/login');
    }
  });

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-logo">*</div>
        <h1>Hello<br />SaleSkip! 👋</h1>
        <p>Skip repetitive and manual sales-marketing tasks. Get highly productive through automation and save tons of time!</p>
        <div className="login-copyright">© 2022 SaleSkip. All rights reserved.</div>
      </div>
      <div className="login-right">
        <div className="login-box">
          <div className="login-header">
            <h2>SaleSkip</h2>
            <h3>{t('auth.signup')}</h3>
            <p>Already have an account? <a href="#" onClick={(e) => { e.preventDefault(); navigate('/login'); }}>Login now</a></p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="field">
              <InputText
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={formik.errors.email ? 'p-invalid' : ''}
                placeholder="Email"
              />
              {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
            </div>

            <div className="field">
              <Password
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className={formik.errors.password ? 'p-invalid' : ''}
                toggleMask
                placeholder="Password"
              />
              {formik.errors.password && <small className="p-error">{formik.errors.password}</small>}
            </div>

            <div className="field">
              <Password
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                className={formik.errors.confirmPassword ? 'p-invalid' : ''}
                toggleMask
                placeholder="Confirm Password"
                feedback={false}
              />
              {formik.errors.confirmPassword && <small className="p-error">{formik.errors.confirmPassword}</small>}
            </div>

            <Button type="submit" label={t('auth.signup')} className="login-button" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp; 