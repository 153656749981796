import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { MenuItem } from 'primereact/menuitem';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const menuRef = useRef<Menu>(null);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    window.location.reload(); // Sayfayı yenile
  };

  const languages: MenuItem[] = [
    {
      label: 'Türkçe',
      icon: 'pi pi-flag-fill',
      command: () => handleLanguageChange('tr')
    },
    {
      label: 'English',
      icon: 'pi pi-flag-fill',
      command: () => handleLanguageChange('en')
    }
  ];

  return (
    <div>
      <Button
        icon="pi pi-globe"
        rounded
        text
        severity="secondary"
        aria-label="Language Menu"
        aria-controls="language-menu"
        aria-haspopup
        onClick={(e) => menuRef.current?.toggle(e)}
      />
      <Menu
        model={languages}
        popup
        ref={menuRef}
        id="language-menu"
      />
    </div>
  );
};

export default LanguageSwitcher; 