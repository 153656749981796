import React from 'react';
import { Permission } from '../types/auth';
import { useAuth } from '../contexts/AuthContext';

interface PermissionGuardProps {
  resource: string;
  permissions: Permission[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
  requireAll?: boolean;
}

const PermissionGuard: React.FC<PermissionGuardProps> = ({
  resource,
  permissions,
  children,
  fallback = null,
  requireAll = true
}) => {
  const { hasPermission } = useAuth();

  const hasAccess = requireAll
    ? permissions.every(permission => hasPermission(resource, permission))
    : permissions.some(permission => hasPermission(resource, permission));

  return hasAccess ? <>{children}</> : <>{fallback}</>;
};

export default PermissionGuard; 