import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import keycloak from '../config/keycloak';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  isAuthenticated: boolean;
  user: any;
  login: () => void;
  logout: () => void;
  token: string | null;
  hasPermission: (resource: string, permission: string) => boolean;
  setToken: (token: string | null) => void;
  setUser: (user: any) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
  token: null,
  hasPermission: () => false,
  setToken: () => {},
  setUser: () => {},
  setIsAuthenticated: () => {}
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const initialized = useRef(false);

  useEffect(() => {
    const initKeycloak = async () => {
      if (initialized.current) return;
      initialized.current = true;

      try {
        const authenticated = await keycloak.init({
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
          pkceMethod: 'S256'
        });

        if (authenticated) {
          setIsAuthenticated(true);
          setToken(keycloak.token || null);
          const userProfile = await keycloak.loadUserProfile();
          setUser(userProfile);
        } else {
          setIsAuthenticated(false);
          setUser(null);
          setToken(null);
        }

        // Token yenileme için interval
        const refreshInterval = setInterval(() => {
          if (keycloak.authenticated) {
            keycloak.updateToken(70)
              .then((refreshed: boolean) => {
                if (refreshed) {
                  setToken(keycloak.token || null);
                }
              })
              .catch(() => {
                console.error('Token refresh failed');
              });
          }
        }, 60000); // Her dakika kontrol et

        return () => {
          clearInterval(refreshInterval);
        };
      } catch (error) {
        console.error('Keycloak initialization failed:', error);
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
      }
    };

    initKeycloak();
  }, []);

  const login = async () => {
    try {
      await keycloak.login();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = async () => {
    try {
      await keycloak.logout({ redirectUri: window.location.origin });
      setIsAuthenticated(false);
      setUser(null);
      setToken(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const hasPermission = (resource: string, permission: string): boolean => {
    if (!isAuthenticated || !user) return false;

    // Keycloak'tan gelen rolleri ve yetkileri kontrol et
    const roles = keycloak.realmAccess?.roles || [];
    const resourceRoles = keycloak.resourceAccess?.[resource]?.roles || [];

    // Admin rolü varsa tüm izinlere sahip
    if (roles.includes('admin')) return true;

    // Resource bazlı yetki kontrolü
    return resourceRoles.includes(permission);
  };

  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated, 
        user, 
        login, 
        logout, 
        token, 
        hasPermission,
        setToken,
        setUser,
        setIsAuthenticated
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}; 