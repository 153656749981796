import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Layout from '../../components/Layout';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Role, Resources, Permission } from '../../types/auth';

interface ResourcePermissionState {
  resource: string;
  view: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
}

const RoleForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [resourcePermissions, setResourcePermissions] = useState<ResourcePermissionState[]>(
    Object.values(Resources).map(resource => ({
      resource,
      view: false,
      create: false,
      update: false,
      delete: false
    }))
  );

  useEffect(() => {
    if (id) {
      // API'den rol bilgilerini al
      const role = {
        id: '1',
        name: 'Admin',
        description: 'Tam yetkili kullanıcı',
        permissions: [
          {
            resource: Resources.DASHBOARD,
            permissions: ['view']
          },
          {
            resource: Resources.USERS,
            permissions: ['view', 'create', 'update', 'delete']
          }
        ],
        isSystem: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'system'
      };

      setName(role.name);
      setDescription(role.description || '');
      
      // Yetkileri tabloya dönüştür
      const permissions = resourcePermissions.map(rp => {
        const rolePermission = role.permissions.find(p => p.resource === rp.resource);
        return {
          resource: rp.resource,
          view: rolePermission?.permissions.includes('view') || false,
          create: rolePermission?.permissions.includes('create') || false,
          update: rolePermission?.permissions.includes('update') || false,
          delete: rolePermission?.permissions.includes('delete') || false
        };
      });
      setResourcePermissions(permissions);
    }
  }, [id]);

  const handleSave = () => {
    // Form validasyonu
    if (!name.trim()) {
      // Hata göster
      return;
    }

    // Yetkileri API formatına dönüştür
    const permissions = resourcePermissions
      .map(rp => {
        const perms: Permission[] = [];
        if (rp.view) perms.push('view');
        if (rp.create) perms.push('create');
        if (rp.update) perms.push('update');
        if (rp.delete) perms.push('delete');

        return perms.length > 0
          ? {
              resource: rp.resource,
              permissions: perms
            }
          : null;
      })
      .filter(p => p !== null);

    const role: Partial<Role> = {
      name,
      description: description || undefined,
      permissions: permissions as Role['permissions']
    };

    if (id) {
      // Rol güncelleme
      console.log('Rol güncellendi:', { id, ...role });
    } else {
      // Yeni rol oluşturma
      console.log('Yeni rol oluşturuldu:', {
        id: uuidv4(),
        ...role,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'current-user'
      });
    }

    navigate('/roles');
  };

  const handlePermissionChange = (rowData: ResourcePermissionState, field: keyof ResourcePermissionState) => {
    if (field === 'resource') return;

    setResourcePermissions(prev =>
      prev.map(rp =>
        rp.resource === rowData.resource
          ? { ...rp, [field]: !rowData[field] }
          : rp
      )
    );
  };

  const permissionBodyTemplate = (rowData: ResourcePermissionState, field: keyof ResourcePermissionState) => {
    if (field === 'resource') {
      return t(`resource.${rowData.resource}`);
    }

    return (
      <Checkbox
        checked={rowData[field] as boolean}
        onChange={() => handlePermissionChange(rowData, field)}
      />
    );
  };

  const toolbarLeft = (
    <div>
      <Button
        label={t('common.save')}
        icon="pi pi-save"
        severity="success"
        onClick={handleSave}
      />
    </div>
  );

  const toolbarRight = (
    <Button
      label={t('common.cancel')}
      icon="pi pi-times"
      severity="secondary"
      onClick={() => navigate('/roles')}
    />
  );

  return (
    <Layout>
      <div className="card">
        <Toolbar start={toolbarLeft} end={toolbarRight} />

        <div className="grid mt-3">
          <div className="col-12 md:col-6">
            <Card title={t('role.details')}>
              <div className="p-fluid">
                <div className="field">
                  <label htmlFor="name">{t('role.name')}</label>
                  <InputText
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="description">{t('role.description')}</label>
                  <InputTextarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={3}
                  />
                </div>
              </div>
            </Card>
          </div>

          <div className="col-12">
            <Card title={t('role.permissions')}>
              <DataTable
                value={resourcePermissions}
                showGridlines
                stripedRows
                size="small"
              >
                <Column
                  field="resource"
                  header={t('role.resource')}
                  body={(rowData) => permissionBodyTemplate(rowData, 'resource')}
                />
                <Column
                  field="view"
                  header={t('permission.view')}
                  body={(rowData) => permissionBodyTemplate(rowData, 'view')}
                />
                <Column
                  field="create"
                  header={t('permission.create')}
                  body={(rowData) => permissionBodyTemplate(rowData, 'create')}
                />
                <Column
                  field="update"
                  header={t('permission.update')}
                  body={(rowData) => permissionBodyTemplate(rowData, 'update')}
                />
                <Column
                  field="delete"
                  header={t('permission.delete')}
                  body={(rowData) => permissionBodyTemplate(rowData, 'delete')}
                />
              </DataTable>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RoleForm; 