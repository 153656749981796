import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Item } from '../../types/item';

// Örnek veri
const initialItems: Item[] = [
  {
    id: '1',
    code: 'ITM001',
    name: 'Laptop',
    price: 1200,
    purchasePrice: 1000,
    vatRate: 18,
    unit: 'piece',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: '2',
    code: 'ITM002',
    name: 'Mouse',
    price: 50,
    purchasePrice: 30,
    vatRate: 18,
    unit: 'piece',
    createdAt: new Date(),
    updatedAt: new Date()
  }
];

const ItemList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [items, setItems] = useState<Item[]>(initialItems);
  const [globalFilter, setGlobalFilter] = useState('');

  const priceBodyTemplate = (rowData: Item) => {
    return `$${rowData.price.toFixed(2)}`;
  };

  const purchasePriceBodyTemplate = (rowData: Item) => {
    return `$${rowData.purchasePrice.toFixed(2)}`;
  };

  const vatRateBodyTemplate = (rowData: Item) => {
    return `${rowData.vatRate}%`;
  };

  const unitBodyTemplate = (rowData: Item) => {
    return t(`item.unit.${rowData.unit}`);
  };

  const actionBodyTemplate = (rowData: Item) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-eye"
          rounded
          text
          severity="info"
          onClick={() => navigate(`/items/view/${rowData.id}`)}
        />
        <Button
          icon="pi pi-pencil"
          rounded
          text
          severity="success"
          onClick={() => navigate(`/items/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const confirmDelete = (item: Item) => {
    confirmDialog({
      message: t('item.confirmDeleteMessage', { code: item.code }),
      header: t('item.confirmDelete'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: t('common.yes'),
      rejectLabel: t('common.no'),
      accept: () => handleDelete(item.id)
    });
  };

  const handleDelete = (id: string) => {
    setItems(items.filter(item => item.id !== id));
  };

  const header = (
    <div className="flex justify-content-between align-items-center">
      <h2 className="m-0">{t('item.list')}</h2>
      <div className="flex gap-2">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder={t('common.search')}
          />
        </span>
        <Button
          label={t('item.add')}
          icon="pi pi-plus"
          severity="success"
          onClick={() => navigate('/items/new')}
        />
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="card">
        <DataTable
          value={items}
          paginator
          rows={10}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}
          emptyMessage={t('item.noItems')}
          stripedRows
          showGridlines
          size="small"
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column field="code" header={t('item.code')} sortable />
          <Column field="name" header={t('item.name')} sortable />
          <Column
            field="price"
            header={t('item.price')}
            body={priceBodyTemplate}
            sortable
          />
          <Column
            field="purchasePrice"
            header={t('item.purchasePrice')}
            body={purchasePriceBodyTemplate}
            sortable
          />
          <Column
            field="vatRate"
            header={t('item.vatRate')}
            body={vatRateBodyTemplate}
            sortable
          />
          <Column
            field="unit"
            header={t('item.unit')}
            body={unitBodyTemplate}
            sortable
          />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }} />
        </DataTable>
        <ConfirmDialog />
      </div>
    </Layout>
  );
};

export default ItemList; 