import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import Layout from '../components/Layout';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="grid">
        <div className="col-12">
          <Card title={t('dashboard.title')}>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3">
                <Card className="bg-blue-50">
                  <div className="text-center">
                    <i className="pi pi-users text-4xl text-blue-500 mb-3"></i>
                    <h3>{t('dashboard.totalCustomers')}</h3>
                    <h2 className="text-blue-500">150</h2>
                  </div>
                </Card>
              </div>
              <div className="col-12 md:col-6 lg:col-3">
                <Card className="bg-green-50">
                  <div className="text-center">
                    <i className="pi pi-check-circle text-4xl text-green-500 mb-3"></i>
                    <h3>{t('dashboard.activeProjects')}</h3>
                    <h2 className="text-green-500">25</h2>
                  </div>
                </Card>
              </div>
              <div className="col-12 md:col-6 lg:col-3">
                <Card className="bg-yellow-50">
                  <div className="text-center">
                    <i className="pi pi-clock text-4xl text-yellow-500 mb-3"></i>
                    <h3>{t('dashboard.pendingTasks')}</h3>
                    <h2 className="text-yellow-500">45</h2>
                  </div>
                </Card>
              </div>
              <div className="col-12 md:col-6 lg:col-3">
                <Card className="bg-purple-50">
                  <div className="text-center">
                    <i className="pi pi-chart-line text-4xl text-purple-500 mb-3"></i>
                    <h3>{t('dashboard.revenue')}</h3>
                    <h2 className="text-purple-500">$52,450</h2>
                  </div>
                </Card>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard; 