import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import keycloak from '../config/keycloak';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, setToken, setUser, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('validation.required')),
      password: Yup.string().required(t('validation.required'))
    }),
    onSubmit: async (values) => {
      try {
        await keycloak.login({
          loginHint: values.username
        });

        if (keycloak.authenticated) {
          setIsAuthenticated(true);
          setToken(keycloak.token || null);
          const userProfile = await keycloak.loadUserProfile();
          setUser(userProfile);
          navigate('/');
        }
      } catch (error) {
        console.error('Login failed:', error);
        formik.setErrors({ password: t('auth.loginFailed') });
      }
    }
  });

  const isFormFieldInvalid = (name: 'username' | 'password') => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: 'username' | 'password') => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : null;
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-logo">{t('app.title')}</div>
        <h1>Modern CRM Çözümü</h1>
        <p>
          Müşteri ilişkilerinizi yönetmek, satışlarınızı takip etmek ve işinizi büyütmek için
          ihtiyacınız olan tüm araçlar tek bir platformda.
        </p>
        <div className="login-copyright">
          © 2024 SaleSkip CRM. Tüm hakları saklıdır.
        </div>
      </div>
      <div className="login-right">
        <Card className="login-box">
          <div className="login-header">
            <h2>{t('auth.login')}</h2>
            <p>Devam etmek için giriş yapın</p>
          </div>

          <form onSubmit={formik.handleSubmit} className="flex flex-column gap-3">
            <div className="field">
              <label htmlFor="username" className="block mb-2">
                {t('auth.username')}
              </label>
              <InputText
                id="username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={isFormFieldInvalid('username') ? 'p-invalid w-full' : 'w-full'}
              />
              {getFormErrorMessage('username')}
            </div>

            <div className="field">
              <label htmlFor="password" className="block mb-2">
                {t('auth.password')}
              </label>
              <Password
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={isFormFieldInvalid('password') ? 'p-invalid w-full' : 'w-full'}
                feedback={false}
                toggleMask
              />
              {getFormErrorMessage('password')}
            </div>

            <Button
              label={t('auth.login')}
              icon="pi pi-sign-in"
              className="login-button mt-3"
              type="submit"
              loading={formik.isSubmitting}
            />
          </form>

          <div className="forgot-password mt-3">
            {t('auth.noAccount')}
            <a href="/signup">{t('auth.signup')}</a>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login; 