import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import Layout from '../../components/Layout';

interface UserFormData {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  roles: string[];
  isActive: boolean;
}

const UserForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const availableRoles = [
    { label: 'Admin', value: 'admin' },
    { label: 'User', value: 'user' },
    { label: 'Manager', value: 'manager' }
  ];

  const formik = useFormik<UserFormData>({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      roles: [],
      isActive: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validation.email')).required(t('validation.required', { field: 'Email' })),
      password: id ? Yup.string() : Yup.string().required(t('validation.required', { field: t('auth.password') })),
      firstName: Yup.string().required(t('validation.required', { field: t('user.firstName') })),
      lastName: Yup.string().required(t('validation.required', { field: t('user.lastName') })),
      roles: Yup.array().min(1, t('validation.required', { field: t('user.roles') }))
    }),
    onSubmit: async (values) => {
      console.log('Form values:', values);
      navigate('/users');
    }
  });

  return (
    <Layout>
      <div className="card">
        <h2>{id ? t('user.edit') : t('user.new')}</h2>
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={formik.errors.email ? 'p-invalid' : ''}
                />
                {formik.errors.email && <small className="p-error">{formik.errors.email}</small>}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="password">{t('auth.password')}</label>
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={formik.errors.password ? 'p-invalid' : ''}
                  toggleMask
                />
                {formik.errors.password && <small className="p-error">{formik.errors.password}</small>}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="firstName">{t('user.firstName')}</label>
                <InputText
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  className={formik.errors.firstName ? 'p-invalid' : ''}
                />
                {formik.errors.firstName && <small className="p-error">{formik.errors.firstName}</small>}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="lastName">{t('user.lastName')}</label>
                <InputText
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  className={formik.errors.lastName ? 'p-invalid' : ''}
                />
                {formik.errors.lastName && <small className="p-error">{formik.errors.lastName}</small>}
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="field">
                <label htmlFor="roles">{t('user.roles')}</label>
                <MultiSelect
                  id="roles"
                  name="roles"
                  value={formik.values.roles}
                  options={availableRoles}
                  onChange={formik.handleChange}
                  className={formik.errors.roles ? 'p-invalid' : ''}
                />
                {formik.errors.roles && <small className="p-error">{formik.errors.roles}</small>}
              </div>
            </div>

            <div className="col-12">
              <div className="field-checkbox">
                <Checkbox
                  id="isActive"
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                />
                <label htmlFor="isActive" className="ml-2">{t('user.isActive')}</label>
              </div>
            </div>
          </div>

          <div className="flex justify-content-end gap-2">
            <Button
              type="button"
              label={t('common.cancel')}
              className="p-button-text"
              onClick={() => navigate('/users')}
            />
            <Button
              type="submit"
              label={id ? t('common.save') : t('common.create')}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default UserForm; 